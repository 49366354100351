import Head from 'next/head';
import dynamic from 'next/dynamic';
import { useEffect } from 'react';
import { useRequest } from 'ahooks';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import type { NextPageContext } from 'next';

import { BannerSwiper } from '@easygo/shared/src/components';
import { easygoTracker } from '@easygo/components';
import { BannerPositionType } from '@easygo/utils/src/enums';

import { LayoutHeaderOffice } from '@/components/layout-header-office';
import { OperationId_salesgateway_HomeController_banner } from '@/server/api/salesgatewayHomeController';

import styles from './index.module.less';

const DepotDashboard = dynamic(() => import('@/components/depot/dashboard'), { ssr: false })!;

export default () => {
  const { t } = useTranslation(['website', 'common']);

  const bannerServer = useRequest(OperationId_salesgateway_HomeController_banner, {
    defaultParams: [{ position: BannerPositionType.YARD_CHOICE_NESS }],
  });

  useEffect(() => {
    // 堆场页面埋点
    easygoTracker('enter_site_depot');
  }, []);

  const bannerClick = (banner: any) => {
    // banner埋点
    easygoTracker('click_banner_depot', {
      name: banner.name,
      index: banner.index,
    });
  };

  return (
    <>
      <Head>
        <title>{t('seoHead.home.title', { ns: 'website' })}</title>
        <meta name="keywords" content={t('seoHead.home.keywords', { ns: 'website' })} />
        <meta name="description" content={t('seoHead.home.description', { ns: 'website' })} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=yes" />
      </Head>
      <LayoutHeaderOffice clsName={styles.header} />
      <div className={styles.content}>
        <BannerSwiper imgs={bannerServer.data?.materials || []} height="240px" bannerClick={bannerClick} />
        <DepotDashboard hasBanner={bannerServer.data?.materials?.length! > 0} />
      </div>
    </>
  );
};

export async function getStaticProps(context: NextPageContext) {
  return {
    props: {
      ...(await serverSideTranslations(context.locale!, ['auth', 'website', 'common', 'enums', 'message', 'translation'])),
    },
  };
}
