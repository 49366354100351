import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import type { NextPageContext } from 'next';
import { utils } from '@easygo/utils';
import Depot from './depot';
import FreightService from './freightService';

export default function Page() {
  return process.env.DEPOT_WEBSITE ? <Depot></Depot> : <FreightService></FreightService>;
}

Page.trackerPageName = utils.ignoreI18n(process.env.DEPOT_WEBSITE ? '全球精选堆场' : '交易中心');

export const getStaticProps = async (context: NextPageContext) => {
  let props: any = {
    ...(await serverSideTranslations(context.locale!, [
      'auth',
      'common',
      'rentBox',
      'enums',
      'message',
      'ocean',
      'website',
      'cashier',
      'translation',
      'railway',
      'shipping',
      'auth',
    ])),
  };
  return { props };
};
